import { defineStore, storeToRefs } from 'pinia';
import {
  editListing,
  createPostlisting,
  getMarketplaceListings,
  GetListingFromSlug,
  getpostlisting,
} from '@/shared/actions/marketplace';
import { sendAnalyticsEvent } from '@/shared/services/analytics';
import { MarketplaceListing, Paging } from '@/shared/types/static-types';
import constants from '@/shared/statics/constants';

export interface IMarketplaceStore {
  activeListing: MarketplaceListing | null;
  marketplaceListings: MarketplaceListing[];
  marketplaceListingsPaging: Paging;
  marketplaceListingsPage: number;
}
export const useMarketplaceStore = defineStore('character-editor', {
  state: (): IMarketplaceStore => ({
    activeListing: null,
    marketplaceListings: [],
    marketplaceListingsPaging: {
      next: '', previous: '', count: 0, results: []
    },
    marketplaceListingsPage: 0,
  }),
  getters: {
    listing(): MarketplaceListing | null {
      return this.activeListing;
    },
    listings(): MarketplaceListing[] {
      return this.marketplaceListings;
    },
    paging(): Paging {
      return this.marketplaceListingsPaging;
    }
  },
  actions: {
    async createMarketplaceListing(listingData: MarketplaceListing) {
      const data = await createPostlisting(listingData);
      sendAnalyticsEvent('Marketplace listing created');
      return data;
    },

    async getHomeListings({ page = 1, pageSize = constants.defaultPageSize }) {
      const { results, ...paging } = await getMarketplaceListings(page, pageSize);
      this.marketplaceListings = results;
      this.marketplaceListingsPaging = paging;
    },

    async getListing(id: string) { // query by ID
      const listing = await getpostlisting(id);
      this.activeListing = listing;
      return listing;
    },

    async getListingFromSlug(slug: string) { // query by slug
      const listing = await GetListingFromSlug(slug);
      this.activeListing = listing;
      return listing;
    },
  
    async fetchHomeListingsNextPage() {
      const marketplaceListingsPage = this.marketplaceListingsPage || 0;
      const { results, ...paging } = await getMarketplaceListings(marketplaceListingsPage + 1);
      this.marketplaceListings = [...(this.marketplaceListings || []), ...results];
      this.marketplaceListingsPaging = paging;
      this.marketplaceListingsPage = (this.marketplaceListingsPage) + 1;
    },

    initNewActiveListing() {
      this.activeListing = {
        title: '',
        tags: []
      }
    },

    async updateListing(payload: MarketplaceListing) {
      return editListing(payload.id!, payload);
    },
  },
});

export const marketplaceStore = () => {
  const store = useMarketplaceStore();
  return {
    ...store,
    ...storeToRefs(store),
  };
};